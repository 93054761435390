<template>
  <div class="pa-4">
    <v-dialog v-model="dialog" v-if="dialog" scrollable max-width="90%" height="90%" persistent :transition="false">

      <v-card class="mt-2 py-2">
        <v-card-title>
            <span class="text-h5">Input Stock Opname</span>
            <v-spacer></v-spacer>
        </v-card-title>    
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form ref="orderform" id="orderform" v-model="valid" lazy-validation>
            <v-row class="mx-2 py-0 compact-form">
              <v-col cols="6">
                <v-layout class="my-2">
                  <v-flex align-self-center md4>Tanggal Stock Opname</v-flex>
                  <v-flex md8>
                    <v-menu v-model="dateDelivMenu" :close-on-content-click="true" :nudge-right="40" transition="scale-transition" offset-y min-width="auto" :disabled="so_no">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="date_count" prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" hide-details="auto" dense outlined :rules="reqRule"></v-text-field>
                      </template>
                      <v-date-picker v-model="date_count" :allowed-dates="allowedDates"></v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-col>
              <v-col cols="6">

              </v-col>
            </v-row>
            <v-row class="mx-2 compact-form">
              <v-col cols="4">
                <v-btn depressed color="primary" @click="loadItems">Manual Input</v-btn>
              </v-col>
              <v-col cols="4">
                <v-file-input outlined dense label="Upload CSV" @change="onFileChange" prepend-icon="mdi-upload" hide-details="auto"></v-file-input>
              </v-col>

            </v-row>

          <v-row class="mb-2 mt-0">
            <v-col class="ml-2">
              <v-card outlined class="ma-3 mt-5" width="980px">
                <v-simple-table fixed-header height="400px" id="variantTbl">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Code</th>
                        <th class="text-left">Description</th>
                        <th class="text-left">Estimasi Stok Akhir</th>
                        <th class="text-left">Qty Count</th>
                        <th class="text-left">Selisih (kg)</th>
                        <th class="text-left">Deviasi (%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, vidx) in items" :key="vidx">
                        <td>{{item.code}}</td>
                        <td>{{item.description}}</td>
                        <td>{{item.estimasi_stok_akhir | rounded2}}</td>
                        <td>
                          <v-text-field type="number" v-model="item.qty_count_temp" dense outlined style="width:150px" hide-details="auto" class="compact-form" @wheel="$event.target.blur()" :rules="reqRule"></v-text-field>
                        </td>
                        <td>
                          {{Number(item.qty_count_temp) - Number(item.estimasi_stok_akhir) | rounded2}}
                        </td>
                        <td>
                          {{((Number(item.qty_count_temp) - Number(item.estimasi_stok_akhir))/Number(item.estimasi_stok_akhir))*100 | rounded2}}%
                        </td>
                      </tr>

                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        </v-card-text>   

        <v-divider></v-divider>
        <v-card-actions class="pr-4">
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false">Close</v-btn>
          <v-btn @click="saveSO(false)" color="primary">Save</v-btn>
          <v-btn v-if="profile.role_code=='SUP'" @click="saveSO(true)" color="success">Approve</v-btn>
        </v-card-actions> 
      </v-card>
    </v-dialog>
    <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
    <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>
    <v-overlay :value="onProgress" z-index="999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>        

  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      reqRule: [v => !!v || 'Harus diisi!'],
      saved: false,
      saveError: false,
      onProgress: false,
      dialog: false,
      dateDelivMenu: false,
      dateExpMenu: false,
      valid: true,
      so_no: null,
      dialogWarning: false,
      // date_count: (new Date()).toISOString().split('T')[0],
      date_count: null,
      allowedDates: val => new Date(val).getDay() === 4,
      items: [],
      rawMaterials: null
    }
  },
  computed: {
    prodOrder() {
      return this.$store.state.deliveryorder.obj
    },
    groups() {
      return this.$store.state.ordercategory.objs
    },
    variants() {
      return this.$store.state.variant.objs
    },
    // rawMaterials() {
    //   return this.$store.state.rawmaterial.objs
    // },
    profile () {
      return this.$store.state.profile
    },

  },
  filters: {
  },
  async created() {
  },
  methods: {
    async loadItems() {
      this.onProgress = true
      this.onClear()
      let dt = new Date(this.date_count + ' 00:00:00')
      dt.setDate(dt.getDate() - 6)
      dt = moment(dt).format('YYYY-MM-DD')
      if (this.$route.params.type == 'RM') {
        this.rawMaterials = (await this.$store.dispatch('getObjs',{apidomain:'others/frm005/', parameter:dt})).data
        this.rawMaterials.forEach(el => {
          // if (el.category_code=='RAW') {
            this.items.push({
              code: el.code,
              description: el.description,
              estimasi_stok_akhir: Number(el.stok_aktual_awal) + Number(el.stockin_total) - Number(el.use_total),
              stok_aktual_awal: el.stok_aktual_awal,
              stockin_total: el.stockin_total,
              use_total: el.use_total,
              prev_qty: Number(el.stok_aktual_awal) + Number(el.stockin_total) - Number(el.use_total),
              qty_count_temp: '0',
              unit_cost: el.cost || 0
            })
          // }
        })
      }
      else if (this.$route.params.type == 'LB') {
        this.rawMaterials = (await this.$store.dispatch('getObjs',{apidomain:'others/frm005lb/', parameter:dt})).data
        console.log(this.rawMaterials)
        // this.rawMaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
        this.rawMaterials.forEach(el => {
          // if (el.category_code=='LNB') {
            this.items.push({
              code: el.code,
              description: el.description,
              estimasi_stok_akhir: Number(el.stok_aktual_awal) + Number(el.stockin_total) - Number(el.use_total),
              stok_aktual_awal: el.stok_aktual_awal,
              stockin_total: el.stockin_total,
              use_total: el.use_total,
              prev_qty: Number(el.stok_aktual_awal) + Number(el.stockin_total) - Number(el.use_total),
              qty_count_temp: '0',
              unit_cost: el.cost || 0
            })
          // }
        })
      }
      else {
        this.variants.forEach(el => {
          const prev_qty = (isNaN(el.qty_stock))? 0 : el.stock_quantity
          this.items.push({
            code: el.code,
            description: el.name,
            prev_qty: prev_qty,
            qty_count_temp: '0',
            unit_cost: 0
          })
        })
      }
      // this.items = this.rawMaterials
      this.items.sort((a, b) => a.description.localeCompare(b.description))
      console.log(this.items)
      this.onProgress = false
    },
    async openDialog() {
      this.onProgress = true
      this.items = []
      this.dialog = true

      await this.$store.dispatch('variant/getObjs')
      if (!this.date_count){
        this.date_count = '2020-01-01'
      }
      let dt = new Date(this.date_count + " 00:00:00")
      dt.setDate(dt.getDate() - 6)
      dt = moment(dt).format('YYYY-MM-DD')
      console.log(this.date_count, dt)
      if (this.$route.params.type=='RM')
        this.rawMaterials = (await this.$store.dispatch('getObjs',{apidomain:'others/frm005/', parameter:dt})).data
      else if (this.$route.params.type=='LB')
        this.rawMaterials = (await this.$store.dispatch('getObjs',{apidomain:'others/frm005lb/', parameter:dt})).data
      if (this.so_no) {
        this.items = (await this.$store.dispatch('getObjs', {apidomain:'stockOpnames/show?type='+this.$route.params.type+'&date_count='+this.date_count, parameter:''})).data.data
        this.items.forEach(el => {
          let obj = {}
          if (this.$route.params.type=='RM' || this.$route.params.type=='LB') {
            obj = this.rawMaterials.find(item => (item.code === el.code))
            this.$set(el, 'description', obj.description)
            this.$set(el, 'estimasi_stok_akhir', Number(obj.stok_aktual_awal) + Number(obj.stockin_total) - Number(obj.use_total))
          }
          else {
            obj = this.variants.find(item => item.code === el.code)
            this.$set(el, 'description', obj.name)
          }
        })
        console.log(this.items)
        this.items.sort((a, b) => a.description.localeCompare(b.description))
      }
      this.onProgress = false
    },

    async saveSO(approve) {
      await this.$refs.orderform.validate();
      if (!this.items.length) {
        this.valid = false
        this.$store.commit('setSnackbar', {saveError: true, errorMessage: "Line tidak boleh kosong!"})        
      }
      if (!this.valid) return;
      this.onProgress = true
      let payload = {opnames: []}
      this.items.forEach(el => {
        el.type = this.$route.params.type.toUpperCase()
        el.date_count = this.date_count
        if (approve) el.qty_count = el.qty_count_temp
        else delete el.qty_count
        payload.opnames.push(el)
      })
      console.log(payload)
      let docs = {}
      try {
        if (this.so_no)
          docs = await this.$store.dispatch('stockopname/putObj', payload)
        else
          docs = await this.$store.dispatch('stockopname/postObj', payload)
      }
      catch (e) {
        this.onProgress = false
        console.log(e)
      }
      if (docs.status==200 || docs.status==201){
        this.onProgress = false
        this.saved = true
        this.dialog = false
        this.$emit('refresh')
      }
      else {
        this.onProgress = false
        this.saveError = true
      }
      console.log(docs)
    },

    onClear() {
      this.items = []
    },
    async onFileChange(e) {
      if (e==null) { // on clear text
        console.log('cleared')
        return this.onClear()
      }
      this.onClear();
      let input = await this.getCsvFile(e)
      const importedItemsTemp = this.csvToJson(input)
      const importedItems = importedItemsTemp.filter(el => {
        return (el.KODE !== null)
      })
      let dt = new Date(this.date_count)
      dt.setDate(dt.getDate() - 6)
      dt = moment(dt).format('YYYY-MM-DD')
      if (this.$route.params.type=='RM')
        this.rawMaterials = (await this.$store.dispatch('getObjs',{apidomain:'others/frm005/', parameter:dt})).data
      else if (this.$route.params.type=='LB')
        this.rawMaterials = (await this.$store.dispatch('getObjs',{apidomain:'others/frm005lb/', parameter:dt})).data
        // this.rawMaterials = (await this.$store.dispatch('rawmaterial/getObjs')).data.data

      importedItems.forEach(async element => {
        console.log(element)
        if (this.$route.params.type == 'RM' || this.$route.params.type == 'LB') {
          let item = this.rawMaterials.find(el => el.code === element.KODE)
          this.items.push({
            code: item.code,
            description: item.description,
            prev_qty: item.stock_quantity,
            qty_count_temp: element.QTY,
            estimasi_stok_akhir: Number(item.stok_aktual_awal) + Number(item.stockin_total) - Number(item.use_total),
            unit_cost: item.cost
          })
        }
        // else if (this.$route.params.type == 'LB') {
        //   let item = this.rawMaterials.find(el => el.code === element.KODE)
        //   this.items.push({
        //     code: item.code,
        //     description: item.description,
        //     prev_qty: item.stock_quantity,
        //     qty_count_temp: element.QTY,
        //     estimasi_stok_akhir: item.stock_quantity,
        //     unit_cost: item.cost
        //   })
        // }
        else {
          let item = this.variants.find(el => el.code === element.KODE)
          this.items.push({
            code: item.code,
            description: item.name,
            prev_qty: item.qty_stock,
            qty_count_temp: element.QTY,
            unit_cost: 0
          })
        }
      });
    },
    getCsvFile(file) {
      return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.readAsText(file);
          reader.onload = function () {
              resolve(reader.result);
          };
          reader.onerror = function (error) {
              reject('Error: ', error);
          };
      })      
    },
    csvToJson (text, headers, quoteChar = '"', delimiter = ',') {
      const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

      const match = line => [...line.matchAll(regex)]
        .map(m => m[2])  // we only want the second capture group
        .slice(0, -1);   // cut off blank match at the end

      const lines = text.split('\n');
      const heads = headers ?? match(lines.shift());

      let filteredLines = lines.filter(function (el) {
        return el != '';
      });

      return filteredLines.map(line => {
          return match(line).reduce((acc, cur, i) => {
            // Attempt to parse as a number; replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur;
            const key = heads[i] ?? `extra_${i}`;
            return { ...acc, [key]: val };
          }, {});
      });
    }    
  }
}
</script>
<style>
  #orderform .col {
    padding: 5px!important;
  }
</style>

<style scoped>
    .centered-input >>> input {
      text-align: center
    }
</style>